@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*{
  font-family: "Poppins", sans-serif !important;
}

.amplify-liveness-cancel-button {
  display: none !important;
}

.amplify-loader {
  display: none;
}

.amplify-liveness-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  display: none;
}

.amplify-alert__heading,
.amplify-alert__body,
.amplify-alert--warning {
  background-color: rgb(233, 119, 119);
}
.amplify-alert--warning {
  border-radius: 13px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.amplify-liveness-camera-module {
  border: none !important;
  background-color: transparent !important;
}

.amplify-button--primary {
  transition: 0.3;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 20.5rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
 /*  background-color: #A61932; */
}

.amplify-button--primary:hover {
  box-shadow: 1px 2px 6px black;
}

.amplify-liveness-toast--primary .amplify-liveness-toast__message,
.amplify-liveness-toast--primary {
  background-color: transparent;
  text-shadow: 0.5px 0.5px 3px black !important;
}

.amplify-liveness-match-indicator__bar:after {
  background-color: gray;
  background: gray;
}
